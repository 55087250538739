import React, { Component } from "react";
import "./App.css";

import { Analytics } from "aws-amplify";

import {
  MDBBtn,
  MDBIcon,
  MDBAnimation,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdbreact";
import { Helmet } from "react-helmet";
import NavbarPage from "../components/nav";

import { Typewriter } from "react-typewriting-effect";
import "react-typewriting-effect/dist/index.css";
import About from "../components/aboutMe";
import Skills from "../components/skills";

import Education from "../components/education";
import RecentPortfolio from "../components/recentPortfolio";

import SocialsBanner from "../components/socilaBanner";
import Footer from "../components/footer";
import Subscribe from "../components/subscribe";
import Contact from "../components/contact";

import WorkExperience from "../components/workExperience";

Analytics.autoTrack("pageView", {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: "HomePageView",
  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes

  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  type: "SPA",
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: "AWSPinpoint",
  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  

  render() {
    return (
      <div>
        <Helmet>
          <title>Adam Satvilker - Blog - Services - Portfolio</title>
          <meta
            name="description"
            content="See my journey as a Computer science student, my portfolio, blogs, services and more. Check out my blog posts for possible inspiration or guidance in fields such as web development and data science."
          />
          <link rel="icon" href="../images/SVG/logo3.svg" />
          <link rel="apple-touch-icon" href="../images/SVG/logo3.svg" />

          <meta
            itemprop="name"
            content="Adam Satvilker - Blog - Services - Portfolio"
          />
          <meta
            itemprop="description"
            content="See my journey as a Computer science student, my portfolio, blogs, services and more. Check out my blog posts for possible inspiration or guidance in fields such as web development and data science."
          />
          <meta itemprop="image" content="../images/BackgroundFinal\(1\).jpg" />

          <meta property="og:url" content="https://www.adamsatvilker.com" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Adam Satvilker - Blog - Services - Portfolio"
          />
          <meta
            property="og:description"
            content="See my journey as a Computer science student, my portfolio, blogs, services and more. Check out my blog posts for possible inspiration or guidance in fields such as web development and data science."
          />
          <meta
            property="og:image"
            content="../images/BackgroundFinal\(1\).jpg"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Adam Satvilker - Blog - Services - Portfolio"
          />
          <meta
            name="twitter:description"
            content="See my journey as a Computer science student, my portfolio, blogs, services and more. Check out my blog posts for possible inspiration or guidance in fields such as web development and data science."
          />
          <meta
            name="twitter:image"
            content="../images/BackgroundFinal\(1\).jpg"
          />
        </Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&display=swap');
        </style>
        
          <div className="bg img-fluid">
            <MDBMask overlay="black-strong">
              <NavbarPage />
              <div
                className="d-flex align-items-center justify-content-between"
                id="welcome-container"
              >
                <MDBRow>
                  <MDBCol size="12" md="6" className="flex-center">
                    <Typewriter
                      className="Typist"
                      string="ADAM"
                      speed={200}
                      stopBlinkinOnComplete
                    />
                  </MDBCol>

                  <MDBCol
                    size="12"
                    md="6"
                    className="flex-center pl-0 pl-md-5"
                    style={{
                      height: "11vh",
                      paddingLeft: "4vh",
                      paddingRight: "0vh",
                    }}
                  >
                    <MDBAnimation type="fadeIn" delay="1s">
                      <Typewriter
                        className="Typist"
                        string="     SATVILKER"
                        speed={200}
                        stopBlinkinOnComplete
                      />
                    </MDBAnimation>
                  </MDBCol>
                </MDBRow>
              </div>
              <MDBContainer className=" d-flex justify-content-center align-items-center subtitle-container">
                <MDBAnimation reveal type="fadeIn" delay="3s">
                  <p id="subtitle">Data Science | FinTech</p>
                </MDBAnimation>
              </MDBContainer>
              <div
                className="d-flex align-items-center justify-content-center"
                id="button-container"
              >
                <div className=" myContainer d-flex  align-self-center">
                  <div className="d-flex flex-column align-items-center">
                    <MDBAnimation reveal type="fadeIn" delay="4s">
                      <p id="seemore">See more</p>
                    </MDBAnimation>
                    <MDBAnimation reveal type="fadeInUp" delay="4s">
                      <MDBAnimation
                        reveal
                        type="bounce"
                        count={3}
                        duration="1000ms"
                        delay="4s"
                      >
                        <MDBBtn
                          tag="a"
                          href="#about"
                          color="transparent"
                          id="down-btn"
                        >
                          <MDBIcon
                            icon="angle-down"
                            size="4x"
                            className="amber-text"
                          ></MDBIcon>
                        </MDBBtn>
                      </MDBAnimation>
                    </MDBAnimation>
                  </div>
                </div>
              </div>
            </MDBMask>
          </div>

          <div id="about">
            <About />
          </div>

          <div id="recentBlog">
            <WorkExperience />
            <RecentPortfolio />
          </div> 
          <div id="skills">
            <Skills />
          </div>

          <div id="education">
            <Education />
          </div>
         

          <div id="contact">
            <MDBMask overlay="stylish-strong" id="outer-contact-mask">
              <Contact />
            </MDBMask>
          </div>
          <div id="socialsBanner">
            <SocialsBanner />
          </div>
          <div id="footer">
            <Footer />
          </div>

          <div id="subscribe">
            <Subscribe />
          </div>
      
      </div>
    );
  }
}

export default App;
