import React, { Component } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import NavbarPage from "./nav";
import {
  MDBMask,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdbreact";

import ReactMarkdown from "react-markdown/with-html";
import RecentBlog from "./recentBlog";
import Subscribe from "./subscribe";

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markdown: null,
      title: null,
      result: [],
      category: "",
      url: "",
      back: "",
    };
  }

  async componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <div>
        <div className="bg-viewBlog img-fluid">
          <MDBMask>
            <NavbarPage />
          </MDBMask>
        </div>
        <div>
          <MDBRow>
            <MDBCol
              size="12"
              md="2"
              className="d-flex flex-column ml-2 mr-2 ml-md-0 mr-md-0 mt-2 mt-md-5 pt-2 pt-md-5 "
            >
              <ins
                className="adsbygoogle d-none d-md-block blogAd"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6848593650304829"
                data-ad-slot="8733711468"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
              <ins
                className="adsbygoogle d-none d-md-block blogAd"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6848593650304829"
                data-ad-slot="8733711468"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
            </MDBCol>
            <MDBCol size="12" md="8">
              <MDBContainer id="post">
                <MDBContainer className="d-flex justify-content-start ml-0 pl-0">
                  <a href={`/${this.props.back}`} id="backToBlog">
                    <MDBIcon icon="arrow-left" /> All post's
                  </a>
                </MDBContainer>

                <MDBContainer
                  className="d-flex flex-column p-md-5 p-0 mt-5"
                  id="article"
                >
                  <MDBContainer className="d-flex flex-column">
                    <section>
                      <header>
                        <h1 id="title">{this.props.result.title}</h1>
                        <h2 id="description">
                          {this.props.result.description}
                        </h2>
                        {/* <ins className="adsbygoogle"
                      style={{display:"block"}}
                      data-ad-format="fluid"
                      data-ad-layout-key="-fb+5w+4e-db+86"
                      data-ad-client="ca-pub-6848593650304829"
                      data-ad-slot="3102767378"></ins>  */}
                      </header>
                      <MDBContainer className="d-flex flex-md-row flex-column justify-content-between pl-0 pr-0">
                        <div className="d-flex flex-row">
                          <MDBIcon
                            icon="user-circle"
                            size="2x"
                            className="grey-text"
                          />
                          <div className="d-flex flex-column">
                            <h3>Adam Satvilker</h3>
                            <div className="d-flex flex-row">
                              <p className="mr-3 info">
                                {this.props.result.date} &diams;{" "}
                                {this.props.result.duration} mins read{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end socials">
                          <FacebookShareButton
                            url={`www.adamsatvilker.com${this.props.url}`}
                            hashtag={`#${this.props.category}`}
                            style={{ height: "max-content" }}
                          >
                            <MDBIcon
                              fab
                              icon="facebook"
                              className="black-text "
                              size="1x"
                            />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={`www.adamsatvilker.com${this.props.url}`}
                            hashtag={`#${this.props.category}`}
                            style={{ height: "max-content" }}
                          >
                            <MDBIcon
                              fab
                              icon="twitter"
                              className="black-text pr-4 pl-4"
                              size="1x"
                            />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            url={`www.adamsatvilker.com${this.props.url}`}
                            hashtag={`#${this.props.category}`}
                            style={{ height: "max-content" }}
                          >
                            <MDBIcon
                              fab
                              icon="linkedin-in"
                              className="black-text "
                              size="1x"
                            />
                          </LinkedinShareButton>
                        </div>
                      </MDBContainer>
                      <figure>
                        <img
                          src={this.props.result.image}
                          alt="Article"
                          width="100%"
                          height="auto"
                          className="z-depth-1"
                        />
                      </figure>
                    </section>
                    <article style={{ fontSize: "2vh" }}>
                      <ReactMarkdown
                        source={this.props.markdown}
                        escapeHtml={false}
                        className="markdown"
                      />
                      <MDBContainer
                        className="pt-4 mt-2"
                        style={{ borderTop: "solid 0.1vh #f7cd52" }}
                      >
                        <div className="d-flex justify-content-md-end justify-content-center">
                          <p
                            style={{
                              paddingTop: "1vh",
                              fontSize: "1.5vh",
                              fontWeight: "bold",
                            }}
                          >
                            Follow me:
                          </p>
                          <MDBBtn
                            tag="a"
                            href="https://www.instagram.com/adamsatvilker/"
                            size="lg"
                            floating
                            color="#ffcb26"
                            id="instagram-btn"
                            className="mr-2 ml-2"
                          >
                            <MDBIcon
                              fab
                              icon="instagram"
                              size="1x"
                              className="amber-text"
                            />
                          </MDBBtn>
                          <MDBBtn
                            href="https://www.linkedin.com/in/adam-satvilker/"
                            target="_blank"
                            size="lg"
                            floating
                            color="#ffcb26"
                            id="instagram-btn"
                            className="mr-2 ml-2"
                          >
                            <MDBIcon
                              fab
                              icon="linkedin"
                              size="1x"
                              className="amber-text"
                            />
                          </MDBBtn>

                          <MDBBtn
                            tag="a"
                            size="lg"
                            floating
                            color="#ffcb26"
                            id="instagram-btn"
                            className="mr-2 ml-2 a"
                          >
                            <MDBIcon
                              fab
                              icon="facebook"
                              size="1x"
                              className="amber-text"
                            />
                          </MDBBtn>
                          <MDBBtn
                            tag="a"
                            size="lg"
                            href="https://medium.com/@asatvilker"
                            floating
                            color="#ffcb26"
                            id="instagram-btn"
                            className="mr-2 ml-2 a"
                          >
                            <MDBIcon
                              fab
                              icon="medium"
                              size="1x"
                              className="amber-text"
                            />
                          </MDBBtn>
                        </div>
                      </MDBContainer>
                    </article>
                  </MDBContainer>
                </MDBContainer>
              </MDBContainer>
            </MDBCol>
            <MDBCol
              size="12"
              md="2"
              className="d-flex flex-column  mr-md-0 mt-2 mt-md-5 pt-2 pt-md-5"
            >
              <ins
                className="adsbygoogle blogAd"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6848593650304829"
                data-ad-slot="8733711468"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
              <ins
                className="adsbygoogle blogAd"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6848593650304829"
                data-ad-slot="8733711468"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
            </MDBCol>
          </MDBRow>
        </div>
        <MDBContainer>
          <div>
            {/* blogBanner */}
            {/* <ins className="adsbygoogle"
                    style={{display:"block"}}
                    data-ad-format="fluid"
                    data-ad-layout-key="-fb+5w+4e-db+86"
                    data-ad-client="ca-pub-6848593650304829"
                    data-ad-slot="3102767378"></ins>
          */}
          </div>
        </MDBContainer>
        <MDBContainer id="recent-blog">
          <RecentBlog />
        </MDBContainer>
        <div id="subscribe">
          <Subscribe />
        </div>
      </div>
    );
  }
}

export default Article;
