import React, {Component} from 'react';
import '../pages/App.css';
import Fade from 'react-reveal/Fade'; 

import { MDBMask, MDBContainer, MDBIcon, MDBRow, MDBCol} from 'mdbreact';

import Quote from './quote';
class Skills extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        collapseIDOther: "",
        collapseIDBackend: "",
      }
    }
    
    
  
    render(){
      return(
      <div>
        <MDBMask  overlay="black-strong align-items-center flex-column" style={{height:"auto"}}>
    
      
          
     
        <MDBContainer className="d-flex flex-md-row flex-column  pl-0 pr-0">
            
        <MDBContainer className="d-flex flex-column">
        <Fade big delay={500} duration={500}>
        <h1 className="headers pl-0 pr-0" responsive>Programming skills</h1>
        <h2 responsive>Languages/frameworks I am familiar with...</h2> 
        </Fade>      
        </MDBContainer>
      </MDBContainer> 
    
      <MDBContainer>
      <MDBRow className="ml-0 mr-0 d-flex justify-content-center">
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon fab icon="python" className="white-text" size="2x"/>
          <h3>Python</h3>
          <p>Advanced</p>
        </MDBCol>
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon fas icon="bezier-curve" className="white-text" size="2x"/>
          <h3>Pytorch/TensorFlow</h3>
          <p>Advanced</p>
        </MDBCol>
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon fas icon="heading" className="white-text" size="2x"/>
          <h3>Haskell</h3>
          <p>Beginner</p>
        </MDBCol>
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon fab icon="aws" className="white-text" size="2x"/>
          <h3>AWS</h3>
          <p>Intermediate</p>
        </MDBCol>
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon icon="database" className="white-text" size="2x"/>
          <h3>SQL</h3>
          <p>Intermediate</p>
        </MDBCol>
      </MDBRow>
    
      <MDBRow className="ml-0 mr-0 d-flex justify-content-center">
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon icon="database" className="white-text" size="2x"/>
          <h3>pySpark</h3>
          <p>Intermediate</p>
        </MDBCol>
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon fab icon="php" className="white-text" size="2x"/>
          <h3>PHP</h3>
          <p>Intermediate</p>
        </MDBCol>
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon fab icon="js" className="white-text" size="2x"/>
          <h3>Javascript/React</h3>
          <p>Advanced</p>
        </MDBCol>
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon fab icon="java" className="white-text" size="2x"/>
          <h3>Java</h3>
          <p>Advanced</p>
        </MDBCol>
        <MDBCol size="12" sm="2" className="skillContainer d-flex flex-column justify-content-around align-items-center">
          <MDBIcon fab icon="r-project" className="white-text" size="2x"/>
          <h3>R</h3>
          <p>Beginner</p>
        </MDBCol>
      </MDBRow>
      
      
    </MDBContainer>
   <Quote />
  

</MDBMask>     
</div>     
        
               
        
  
      )
    }
  }
  
  export default Skills