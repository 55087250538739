import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import "./viewBlog.css";

import { Storage, API, Analytics } from "aws-amplify";
import Article from "../components/article";

Analytics.autoTrack("pageView", {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: "ArticlePageView",
  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes

  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  type: "SPA",
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: "AWSPinpoint",
  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  },
});

class ViewPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markdown: null,
      title: null,
      result: [],
      category: "",
      url: "",
      back: "",
      loaded: false,
    };
  }

  async componentDidMount() {
    var element = document.getElementsByTagName("meta");

    while (element[0]) {
      element[0].remove();
    }

    const path = window.location.pathname;

    this.setState({ url: path });
    const pathArray = path.split("/");

    const title = pathArray[2].replace(/-/g, " ");

    this.setState({
      title: title,
      back: pathArray[1],
    });
    const data = await API.graphql({
      query: `query list{listPortfolios(filter:{title:{eq:"${title}"}} ){
          items{
            id
            title
            image
            description
            likes
            facebook
            twitter
            linkedIn
            link
            date
            duration
            updatedAt
            category{
              topic
            }
          }
          
              }
      }`,
    });

    Storage.get(
      `${data.data.listPortfolios.items[0].id}${data.data.listPortfolios.items[0].updatedAt}.md`
    ).then((result) => {
      //const value="test"
      console.log(result);
      //const md=require(`${result}`)
      var category = data.data.listPortfolios.items[0].category.topic + "";
      var finalCategory = category.replace(/ /g, "-");

      fetch(`${result}`).then((response) =>
        response.text().then((response) => {
          this.setState({
            markdown: response,
            result: data.data.listPortfolios.items[0],
            category: finalCategory,
            loaded: true,
          });
        })
      );
    });

    /* fetch(md).then((response) => response.text()).then((text) => {
        this.setState({markdown: text})
      }) */
  }

  render() {
    return (
      <div id="viewBlog">
        <Helmet>
          <meta charset="utf-8" />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <title>{this.state.result.title}</title>
          <meta
            name="description"
            content={`${this.state.result.description}`}
          />

          <meta itemprop="name" content={`${this.state.result.title}`} />
          <meta
            itemprop="description"
            content={`${this.state.result.description}`}
          />
          <meta itemprop="image" content={`${this.state.result.image}`} />

          <meta
            property="og:url"
            content={`www.adamsatvilker.com${this.state.url}`}
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${this.state.result.title}`} />
          <meta
            property="og:description"
            content={`${this.state.result.description}`}
          />
          <meta property="og:image" content={`${this.state.result.image}`} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${this.state.result.title}`} />
          <meta
            name="twitter:description"
            content={`${this.state.result.description}`}
          />
          <meta name="twitter:image" content={`${this.state.result.image}`} />
        </Helmet>

        {this.state.loaded ? (
          <Article
            back={this.state.back}
            url={this.state.url}
            category={this.state.category}
            markdown={this.state.markdown}
            result={this.state.result}
          />
        ) : (
          <div className="flex-center w-100" style={{ height: "100vh" }}>
            {" "}
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ViewPortfolio;
