/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://af55453ekffk3lhfvq7zrp3uja.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "api226786d8",
            "endpoint": "https://v67ft16t66.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:64f2b50c-0daf-42e4-b941-726e8a5adbe7",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_tqVuu8R5q",
    "aws_user_pools_web_client_id": "6t1o4qvr46m0eq0fvbll7u6ktl",
    "oauth": {},
    "aws_mobile_analytics_app_id": "564e046c81264e1198fea73b62916f2f",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "aws_user_files_s3_bucket": "mywebsitebucket43707-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
