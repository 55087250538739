import React, { Component } from 'react';
import '../pages/blog.css';
import { MDBMask, MDBContainer, MDBIcon, MDBRow, MDBView, MDBCol } from 'mdbreact';

class PortfolioPreview extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        image: '',
        web: 'add a blog'
      }
    }
    
       /* componentDidMount(){
        Storage.get(`${this.props.object.image}`).then(response => {
            this.setState({image: response})
        })
      
      
    }   */
  
    render(){
      return(
      
        <div id="blogComponent">
            <MDBRow className="d-flex ml-0 mr-0">
                



                <MDBCol size="12" md="6">
                    <MDBView hover className="z-depth-3">

             
                        <img src={this.props.object.image} alt="" width="100%" height="auto" />  

                        <a href={ this.props.link }>
                        <MDBMask overlay="black-light" className="flex-center">

                        </MDBMask>
                        </a>
                    </MDBView>

                </MDBCol>
                <MDBCol size="12" md="6" className="pt-3 pt-md-0">
                <MDBContainer className="d-flex flex-column  justify-content-between align-items-start pt-2 pb-2 mr-5 h-100">
            
           
            
                   
            
                  <a href={ this.props.link}><h1 style={{fontSize:"3vh"}} >{this.props.object.title}</h1></a>
                  <p style={{fontSize:"2vh"}}>{this.props.object.description}...</p>
                <MDBRow className="pt-3 w-100">
                  <div className="d-flex justify-content-end w-100">
                    <a href={this.props.link}>Read more >></a>

                  </div>
                    
                </MDBRow>
            </MDBContainer>

                </MDBCol> 
            </MDBRow>
            
           
           
        </div>
      
         
               
        
  
      )
    }
  }
  
  export default PortfolioPreview