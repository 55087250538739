import React, {Component } from 'react';
import './App.css';
import './blog.css';
import "./portfolio.css";
import NavbarPage from '../components/nav';
import { MDBMask,MDBRow, MDBCol } from 'mdbreact';
import BlogNav from '../components/blogNav';
import { Analytics, API } from 'aws-amplify';
import Subscribe from '../components/subscribe';
import {Helmet} from "react-helmet";
import PortfolioPreview from '../components/portfolioPreview';
const initialQuery = `query list{
  portfolioByDate(sortDirection: DESC, type: "post"){
    items{
      id
      image
      date
      duration
      title
      description
      link
      category{
        topic
      }
      
    }
  }
    
        }`;
        Analytics.autoTrack('pageView', {
          // REQUIRED, turn on/off the auto tracking
          enable: true,
          // OPTIONAL, the event name, by default is 'pageView'
          eventName: 'PortfolioPageView',
          // OPTIONAL, the attributes of the event, you can either pass an object or a function 
          // which allows you to define dynamic attributes
          
          // when using function
          // attributes: () => {
          //    const attr = somewhere();
          //    return {
          //        myAttr: attr
          //    }
          // },
          // OPTIONAL, by default is 'multiPageApp'
          // you need to change it to 'SPA' if your app is a single-page app like React
          type: 'SPA',
          // OPTIONAL, the service provider, by default is the Amazon Pinpoint
          provider: 'AWSPinpoint',
          // OPTIONAL, to get the current page url
          getUrl: () => {
              // the default function
              return window.location.origin + window.location.pathname;
          }
        });

class Portfolio extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        query: initialQuery,
        result: [],
        prev: [],
        current: null,
        next:null,
        loaded:false

      }
      this.changeQuery=this.changeQuery.bind(this)
      
    }

    

    
    async componentDidMount(){
      var element = document.getElementsByTagName("meta");
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      

      while(element[0]) {
          element[0].remove()
        }
      const data = await API.graphql({
        query: this.state.query
  
      });
      
      this.setState({
        result: data.data.portfolioByDate.items,
        loaded:true

        
      });
      const result = data.data.portfolioByDate.items
      const count = result.length
      
      
    }
    changeQuery(data) {
      console.log(data.data.portfolioByDateByCategory.items)
      this.setState({
        result: data.data.portfolioByDateByCategory.items
      })
      
  }
  
    render(){
      return(
      
       <div id="blog">
         <Helmet>
         <meta charset="utf-8" />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
         <title>Adam Satvilker - Portfolio</title>
        <meta name="description" content="AWS, AWS Amplify, Data Science and much more interesting content! My way of sharing my knowledge with likeminded anyone else who may need it" />

        
        <meta itemprop="name" content="Adam Satvilker - Portfolio" />
        <meta itemprop="description" content="AWS, AWS Amplify, Data Science and much more interesting content! My way of sharing my knowledge with likeminded anyone else who may need it" />
        <meta itemprop="image" content="https://www.adamsatvilker.com/logo3.png" />

       
        <meta property="og:url" content="https://www.adamsatvilker.com/blog" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Adam Satvilker - Portfilio" />
        <meta property="og:description" content="AWS, AWS Amplify, Data Science and much more interesting content! My way of sharing my knowledge with likeminded anyone else who may need it" />
        <meta property="og:image" content="https://www.adamsatvilker.com/logo3.png" />

        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Adam Satvilker - Portfolio" />
        <meta name="twitter:description" content="AWS, AWS Amplify, Data Science and much more interesting content! My way of sharing my knowledge with likeminded anyone else who may need it" />
        <meta name="twitter:image" content="https://www.adamsatvilker.com/logo3.png" />
         </Helmet>
      
         <div className="bg-portfolio img-fluid " >
        
         <MDBMask overlay="black-strong">
         <NavbarPage />
         <div className="d-flex align-items-center justify-content-center" >
                  <h1 className="white-text " >MY PORTFOLIO...</h1>
                  
          </div>         
        </MDBMask> 
         </div>

      <div id="blogList" className="portfolioList">
      <MDBRow>
      <MDBCol size="12" md="2" className="d-flex flex-column ml-2 mr-2 ml-md-0 mr-md-0 mt-2 mt-md-5 pt-2 pt-md-5 ">
        <ins className="adsbygoogle blogAd d-none d-md-block"
     style={{display:"block"}}
     data-ad-client="ca-pub-6848593650304829"
     data-ad-slot="8733711468"
     data-ad-format="auto"
     data-full-width-responsive="true"
     ></ins>
      <ins className="adsbygoogle d-none d-md-block blogAd"
     style={{display:"block"}}
     data-ad-client="ca-pub-6848593650304829"
     data-ad-slot="8733711468"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
      
        </MDBCol>
        <MDBCol size="12" md="8">
       { 
         this.state.loaded? <MDBRow id="blogContainer">
          <MDBCol size="12" className="d-flex flex-column align-items-center justify-content-center">
        <h2 id="portfolioHeader">A collection of my recent work</h2>
        <BlogNav onClick={this.changeQuery}/>
        </MDBCol>
          {
            
           this.state.result.length === 0?  <p className="message">No posts here yet...</p>: this.state.result.map(data => {
            
            const link = "/portfolio/" + data.title.replace(/ /g, "-");
            
             return (
              <div>
               <MDBCol size="12">
                 <PortfolioPreview object={data} key={data.id} link={link}/>
                 
                 
             </MDBCol>
             
             </div>
             )
         })
              
              
            }
            
          
          
              


        
         
        </MDBRow>: <div className="flex-center w-100" style={{height:"40vh"}}> <div className="spinner-border text-warning" role="status">
          <span className="sr-only">Loading...</span>
        </div></div>
    }
        </MDBCol>
        <MDBCol size="12" md="2" className="d-flex flex-column  mr-md-0 mt-2 mt-md-5 pt-2 pt-md-5 ">
        <ins className="adsbygoogle blogAd"
     style={{display:"block"}}
     data-ad-client="ca-pub-6848593650304829"
     data-ad-slot="8733711468"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
      <ins className="adsbygoogle blogAd"
     style={{display:"block"}}
     data-ad-client="ca-pub-6848593650304829"
     data-ad-slot="8733711468"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
     
        </MDBCol>
      </MDBRow>

      </div>
      <div id="subscribe" >
      <Subscribe />
      </div>
                 
      </div>         
        
  
      )
    }
  }
  
  export default Portfolio