import React, { Component } from 'react';
import '../pages/App.css';
import Fade from 'react-reveal/Fade'; 

import { MDBContainer, MDBTypography, MDBBox } from 'mdbreact';

class Quote extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        machine: 'Add a blog',
        web: 'add a blog'
      }
    }
    
    /*async componentDidMount(){
      console.log('hello')
      const data = await API.graphql({
        query: Query
  
      });
      console.log('hello')
      this.setState({
        machine: data.data.listPostByCategory.items[0].title,
        web: 'loaded'
      });
      console.log('hello')
    }*/
  
    render(){
      return(
      
        <div>
            
            <MDBContainer className="d-flex flex-row justify-content-between pt-4 pb-4 ">

            <Fade big delay={3600}>
            <MDBTypography blockquote className="text-center">
      <         MDBBox tag="p" mb={0}>“Programming isn't about what you know; it's about what you can figure out.”</MDBBox>
                <MDBBox tag="footer" mb={3} className="blockquote-footer"><cite title="Source Title">Chris Pine</cite></MDBBox>
            </MDBTypography>
                <MDBTypography></MDBTypography>
                </Fade>  
            </MDBContainer>
           
        </div>
      
         
               
        
  
      )
    }
  }
  
  export default Quote