import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';

import * as serviceWorker from './serviceWorker';
import {Amplify} from "aws-amplify";
import awsExports from "./aws-exports";
import { Switch, Route } from 'react-router';
import App from './pages/App';
import Portfolio from './pages/portfolio';
import Blog from './pages/blog';
import ViewPortfolio from './pages/viewPortfolio';
import ViewBlog from './pages/viewBlog';
import { BrowserRouter} from 'react-router-dom';
import Privacy from './pages/privacy';



Amplify.configure(awsExports);


ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
    <Switch>
             <Route exact path="/" component={App}/>
             <Route exact path="/portfolio" component={Portfolio}/>
             <Route exact path="/portfolio/:category" component={ViewPortfolio}/>
             <Route exact path="/blog" component={Blog}/>
             <Route exact path="/blog/:category" component={ViewBlog}/>
             <Route exact path="/privacy" component={Privacy}/>
            
            

    </Switch>
  
  
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
// <Link to={`/netflix/${j}`}>Netflix</Link>
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
