import React, { Component } from "react";
import "../pages/App.css";
import Fade from "react-reveal/Fade";
import uniLogo from "../images/190332.png";
import { MDBContainer, MDBIcon, MDBRow, MDBCol } from "mdbreact";
import InterestsBanner from "./interestsBanner";

class Education extends Component {
  constructor(props) {
    super(props);
    this.state = {
      machine: "Add a blog",
      web: "add a blog",
    };
  }

  render() {
    return (
      <div>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Cormorant&family=DM+Serif+Display&family=Rye&display=swap');
        </style>
        <MDBContainer className="d-flex flex-row ">
          <MDBRow>
            <MDBCol size="12" md="6" className="pr-md-5">
              <Fade left big cascade delay={700}>
                <MDBContainer
                  className="d-flex flex-column"
                  style={{ marginRight: "0rem" }}
                >
                  <h1 className="headers" responsive>
                    Education
                  </h1>
                </MDBContainer>
                <MDBContainer className="mr-md-5 mr-0">
                  <p>
                    I am currently an undergraduate studying Computer Science
                    with masters (Msci). I have a strong mathematical
                    background, particularly in statistics. Through my degree I
                    have gained skills in the data science field, from data
                    analytics to AI/ML. The capstone project being an NLP
                    focused hate speech detection chrome extension.
                  </p>
                </MDBContainer>
              </Fade>
            </MDBCol>

            <MDBCol size="12" md="6">
              <MDBContainer className="d-flex flex-column flex-center ">
                <MDBContainer className="d-flex flex-column align-items-center pt-0 pb-5 mt-0 pl-5 educationIcons">
                  <Fade right big cascade delay={700}>
                    <MDBIcon
                      icon="user-graduate"
                      size="8x"
                      className=" mb-5 white-text"
                    />

                    <img src={uniLogo} height="150" width="300" alt=""></img>
                   
                   
                  </Fade>
                </MDBContainer>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer>
          <h1 className="headers" >Interests</h1>
          <InterestsBanner />
        </MDBContainer>
      </div>
    );
  }
}

export default Education;
