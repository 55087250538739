import React, {Component} from 'react';
import './App.css';

import { MDBContainer } from 'mdbreact';
import Subscribe from '../components/subscribe';

class Privacy extends Component{

    constructor(props)
    {
      super(props);
      this.state={
       
      }
    }
    
    componentDidMount(){

        const script = document.createElement("script");

        script.src = "https://consent.cookiebot.com/f6a038df-46da-4169-ab2a-bc896f001158/cd.js";
        script.async = true;
        script.type='text/javascript';
        script.setAttribute('id','CookieDeclaration')
        const element = document.getElementById('privacyPolicy');
        element.appendChild(script)

        
      
    }
  
    render(){
      return(
      
     <div> 
      <MDBContainer className="d-flex flex-column justify-content-center align-items-center mb-5">

        <h1>PRIVACY POLICY</h1>
         <div id="privacyPolicy" >

       
         </div>
         
    </MDBContainer>
<div id="subscribe" className="mt-5">
    <Subscribe />
</div>
</div>             
               
        
  
      )
    }
  }
  
  export default Privacy