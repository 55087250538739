import React, { Component} from 'react';
import '../pages/App.css';
import Fade from 'react-reveal/Fade'; 
import { MDBMask, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BlogPreview from './blogPreview';
import { API } from 'aws-amplify';


const initialQuery = `query list{
    postByDateByCategory(categoryID: "abbec26f-f0e6-46e8-b282-bc131d9c102d", sortDirection: DESC) {
        items {
          id
          image
          date
          duration
          title
          description
          link
          likes
          category {
            topic
          }
        }
      }
    
        }`;

class WorkExperience extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        query: initialQuery,
        result:[]
      }
    }
    
    async componentDidMount(){
      console.log('hello')
      const data = await API.graphql({
        query: this.state.query
  
      });
      console.log('hello')
      this.setState({
        result: data.data.postByDateByCategory.items
        
      });
      console.log('hello')
    }
  
    render(){
      return(
      
        <MDBMask >
          <MDBContainer>
          <Fade  delay={800} big>
            <h2 className="headers">Work Experience</h2>
          </Fade>
            </MDBContainer>
            <MDBContainer className="d-flex justify-content-center align-items-md-start align-items-center flex-column ">
            <MDBRow className="w-100">
              {
                
                this.state.result.length === 0?  <p className="message">No posts here yet...</p>: this.state.result.map(data => {
                  const link = "/blog/" + data.title.replace(/ /g, "-");
                  
                   return (
                    <MDBCol size="12" md="4">
                    <Fade delay={1200}  >
                      
                       <BlogPreview object={data} key={data.id} link={link}/>
                       
                       
                   </Fade>
                   </MDBCol>
                   )
               })
              }
           </MDBRow>
            </MDBContainer>
            <MDBContainer className="d-flex justify-content-center">
            <a href='/blog' style={{marginTop:"5vh"}} id="seeMore">See more &gt;&gt;</a>
            </MDBContainer>

           
            </MDBMask>
      
         
               
        
  
      )
    }
  }
  
  export default WorkExperience