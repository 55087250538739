import React, { Component } from 'react';
import '../pages/App.css';

import { MDBMask, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';

import ContactForm from './form';
class Contact extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        collapseIDOther: "",
        collapseIDBackend: ""
       
        
      }
      
    }
    
    

    
    
    render(){
      return(
        <MDBMask   className="mb-5" style={{backgroundColor: "#06203c"}}>
    <div className="background ">
    <style>
@import url('https://fonts.googleapis.com/css2?family=Cormorant&family=DM+Serif+Display&family=Playfair+Display:wght@500&family=Rye&display=swap');
</style>
    <MDBContainer>
      <MDBRow className="mt-0 pt-0">
            <MDBCol size="12" md="6" style={{paddingRight:"2rem"}}>
                <h2>Let's Talk!</h2>
                <p>If you have any queries, feel free to message me on any of my social media, email me directly or fill out this form and I will email you back as quick as I can.</p>
                
            </MDBCol>
            <MDBCol size="12" md="6" style={{paddingRight:"0rem", paddingLeft:"4rem"}} id="formPadding">
            
     
        
          <MDBCard style={{backgroundColor: "transparent"}} className="z-depth-0">
            <MDBCardBody>
            <ContactForm />
            </MDBCardBody>
          </MDBCard>
        
      
   

            </MDBCol>
          

          </MDBRow>
      </MDBContainer>
          
      </div>
</MDBMask>     
      
        
               
        
  
      )
    }
  }
  
  export default Contact