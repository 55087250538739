import React from "react";
import { useForm } from "react-hook-form";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBContainer,
} from "mdbreact";
import { Amplify, Analytics } from "aws-amplify";

export default function Subscribe() {
  const { register, handleSubmit, errors} = useForm();

  async function onSubmit(data, e) {
    Amplify.configure({
      Analytics: {
        AWSPinpoint: {
          endpointId: data.email,
          ChannelType: "EMAIL",
        },
      },
    });
    Analytics.updateEndpoint({
      address: data.email, // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
      attributes: {
        // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
        hobbies: ["piano", "hiking"],
        email: [data.email],
      },
      ChannelType: "EMAIL",

      optOut: "NONE",
      // Customized userId
      user: {
        userId: data.email,
        // User attributes
        userAttributes: {
          interests: ["football", "basketball", "AWS"],
        }, // ...
      },
    }).then(() => {
      console.log("success");
      document.getElementById("formGroupExampleInput").value = "";
    });
  }

  return (
    <footer>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
      </style>
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12" md="4">
            <h1>PURPOSE</h1>
            <p>
              The purpose of this portfolio website is to demonstrate my skills
              and share my learning experiences. Through blog posts and showcase
              projects, this website will serve as a platform for me to
              communicate the work that I do and also share this with those
              similar to me. This website being my first project.
            </p>
          </MDBCol>
          <MDBCol size="12" md="4">
            <h1>SOCIALS</h1>
            <ul>
              <a
                href="https://www.linkedin.com/in/adam-satvilker/"
                className="footerSocials"
              >
                LinkedIn
              </a>
              <a
                href="https://medium.com/@asatvilker"
                className="footerSocials"
              >
                Medium
              </a>
              <a href="https://github.com/asatvilker" className="footerSocials">
                Github
              </a>
            </ul>
          </MDBCol>
          <MDBCol size="12" md="4">
            <h1>SUBSCRIBE</h1>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="formGroupExampleInput">
                  To receive email updates whenever I post something new, enter
                  your email below and never miss a thing!{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput"
                  name="email"
                  ref={register({
                    required: "true",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                  })}
                />
                {errors.email && errors.email.type === "required" && (
                  <span>Don't forget your email!</span>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <span>Please enter a valid email address</span>
                )}

                <div className="text-center">
                  <MDBBtn type="submit">
                    SUBSCRIBE
                    <MDBIcon far icon="paper-plane" className="ml-1" />
                  </MDBBtn>
                </div>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-md-5">
          <MDBCol
            size="12"
            md="6"
            className="d-flex justify-content-around justify-content-md-between pr-md-5"
          >
            <a href="/" className="footerLinks">
              Home
            </a>
            <a href="/blog" className="footerLinks">
              Blog
            </a>
            <a href="/#contact" className="footerLinks">
              Contact
            </a>
            <a href="/portfolio" className="footerLinks">
              Portfolio
            </a>
            <a href="/privacy" className="footerLinks">
              Privacy
            </a>
          </MDBCol>
          <MDBCol size="12" md="6" className="d-flex justify-content-md-end">
            <p className="copywrite">
              Built from scratch with blood sweat and tears by &copy;Adam
              Satvilker
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </footer>
  );
}
