import React, {Component} from 'react';
import '../pages/App.css';

import Fade from 'react-reveal/Fade'; 
import {  MDBContainer, MDBIcon } from 'mdbreact';

class InterestsBanner extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        machine: 'Add a blog',
        web: 'add a blog'
      }
    }
    
    
  
    render(){
      return(
      
        <div>
            <MDBContainer className="d-flex flex-md-row flex-column justify-content-md-between align-items-center pt-5 pb-0 ">
           <Fade bottom big delay={0} duration={2000}>
                <MDBContainer className="d-flex flex-column align-items-center innerContainers1 pt-0 pb-0 pl-5 pr-5 ml-0 mr-0 ">
                    <MDBIcon fab icon="bitcoin" size="3x" className="white-text"/>
                    <h1 responsive className="interestHeaders">Fintech</h1>
                </MDBContainer>
                </Fade>
              <Fade bottom big delay={0} duration={2000}>
                <MDBContainer className="d-flex flex-column align-items-center innerContainers1 pt-0 pb-0 ml-0 mr-0 pl-5 pr-5 ">
                    <MDBIcon fab icon="aws" size="3x" className="white-text"/>
                    <h1 responsive className="interestHeaders" >Cloud Computing</h1>
                </MDBContainer>
            </Fade>
            <Fade bottom big delay={0} duration={2000}>
                <MDBContainer className="d-flex flex-column align-items-center innerContainers1 pt-0 pb-0 ml-0 mr-0 pl-5 pr-5 ">
                    <MDBIcon icon="funnel-dollar" size="3x" className="white-text"/>
                    <h1 responsive className="interestHeaders">Data Science/ML</h1>
                </MDBContainer>
            </Fade>
                
                
            </MDBContainer>
        </div>
      
         
               
        
  
      )
    }
  }
  
  export default InterestsBanner