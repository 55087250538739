import React, {  Component} from 'react';
import '../pages/App.css';


import { MDBRow, MDBCol, MDBBox } from 'mdbreact';
import Socials from './socialsGroup';

class SocialsBanner extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        machine: 'Add a blog',
        web: 'add a blog'
      }
    }
    
   
  
    render(){
      return(
      
        <div >
            <style>
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Cormorant&family=DM+Serif+Display&family=Playfair+Display:wght@500&family=Rye&display=swap');
</style>
            <MDBBox className="">
                <MDBRow>
                    <MDBCol size="12" md="6" className="flex-center text-center  " style={{height:"20vh", borderRight:"solid 0.05rem whitesmoke"}}>
                        
                        <h1>Keep in <strong>Touch</strong>!</h1>
                        
                    </MDBCol>
                    <MDBCol size="12" md="6" className="flex-center  " style={{height:"20vh", borderLeft:"solid 0.05rem whitesmoke"}}>
                        <Socials/>
                    </MDBCol>
                </MDBRow>
            </MDBBox>
                
                
           
        </div>
      
         
               
        
  
      )
    }
  }
  
  export default SocialsBanner