import React, { Component } from "react";
import "../pages/App.css";

import { MDBBtn, MDBIcon } from "mdbreact";

class Socials extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="d-flex justify-content-md-end justify-content-center">
        <MDBBtn
          href="https://www.linkedin.com/in/adam-satvilker/"
          target="_blank"
          size="lg"
          floating
          color="#ffcb26"
          id="instagram-btn"
          className="mr-2 ml-2"
        >
          <MDBIcon fab icon="linkedin" size="2x" />
        </MDBBtn>
        <MDBBtn
          tag="a"
          href="https://github.com/asatvilker"
          target="_blank"
          size="lg"
          floating
          color="#ffcb26"
          id="instagram-btn"
          className="mr-2 ml-2"
        >
          <MDBIcon fab icon="github" size="2x" />
        </MDBBtn>
        <MDBBtn
          tag="a"
          size="lg"
          href="https://medium.com/@asatvilker"
          floating
          color="#ffcb26"
          id="instagram-btn"
          className="mr-2 ml-2 a"
        >
          <MDBIcon fab icon="medium" size="2x" />
        </MDBBtn>
      </div>
    );
  }
}

export default Socials;
