import React, { Component } from 'react';
import '../pages/App.css';
import Fade from 'react-reveal/Fade'; 

import { MDBContainer, MDBIcon, MDBMask } from 'mdbreact';
import Socials from './socialsGroup';

class About extends Component{

    constructor(props)
    {
      super(props);
      this.state={
       
      }
    }
    
    
  
    render(){
      return(
      
        
      <MDBMask overlay="black-strong">
       
            
        <div className="d-flex flex-md-row-reverse flex-column justify-content-md-start align-items-start">
        
  
          
        
        <Fade right delay={800} >
          <MDBContainer className="d-flex flex-column profileText z-depth-5">
          <h1 className="headers" responsive> About me</h1>
          <h2 responsive><MDBIcon icon="user-secret" size="1x" className="white-text"/> Who am I?</h2>
            <p className="text" >
              Welcome to my creative space. I am currently an undergraduate in my final year, studying <a  href="#education"><strong>computer science</strong></a> at Queen Mary University of London. I have a genuine passion for technology, finance and in general, problem solving. 
            </p>
          <div className="d-flex flex-md-row flex-column justify-content-md-between pt-4  pt-md-4">
            <MDBContainer className="d-flex flex-row justify-content-center align-items-md-around pl-0 pr-0" id="socialEmail">
            
            <a href="mailto:asatvilker.as@gmail.com" id="email">asatvilker.as@gmail.com</a>
            </MDBContainer>
          <MDBContainer className=" flex-row justify-content-start  socials pl-0 pr-0  d-lg-block">
            <Socials />
          </MDBContainer>
          </div>
          </MDBContainer>
        
      </Fade>
        </div> 
      
   
    </MDBMask>
                 
               
        
  
      )
    }
  }
  
  export default About