import React, { Component } from "react";
import "./App.css";
import "./blog.css";
import NavbarPage from "../components/nav";
import { MDBMask, MDBRow, MDBCol } from "mdbreact";
import BlogPreview from "../components/blogPreview";
import BlogNav from "../components/blogNav";
import { Analytics, API } from "aws-amplify";
import Subscribe from "../components/subscribe";
import { Helmet } from "react-helmet";

const initialQuery = `query list{
  postByDate(sortDirection: DESC, type: "post", filter: {categoryID: {ne: "396aa086-5fa8-4d54-a3d0-25df1b5fcbb2"}}){
    items{
      id
      image
      date
      duration
      title
      description
      
      link
      
      category{
        topic
      }
      
    }
  }
    
        }`;
Analytics.autoTrack("pageView", {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: "BlogPageView",
  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes

  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  type: "SPA",
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: "AWSPinpoint",
  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  },
});

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: initialQuery,
      result: [],
      prev: [],
      current: null,
      next: null,
      loaded: false,
    };

    this.changeQuery = this.changeQuery.bind(this);
  }
  changeQuery(data) {
    console.log(data.data.postByDateByCategory.items);
    this.setState({
      result: data.data.postByDateByCategory.items,
    });
    /* if (data.data.listPostByCategory.items.length === 0) {
          const element = document.getElementById("blogContainer")
          const msg = document.createElement("P")
          msg.innerHTML = "No post here yet :("
          const attr= document.createAttribute("class")
          attr.value="message"
          msg.setAttributeNode(attr)
          element.appendChild(msg)
          
        } */
  }

  async componentDidMount() {
    var element = document.getElementsByTagName("meta");
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    (window.adsbygoogle = window.adsbygoogle || []).push({});

    while (element[0]) {
      element[0].remove();
    }
    const data = await API.graphql({
      query: this.state.query,
    });

    this.setState({
      result: data.data.postByDate.items,
      loaded: true,
    });
  }

  render() {
    return (
      <div id="blog">
        <Helmet>
          <meta charset="utf-8" />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <title>Adam Satvilker - Blog</title>
          <meta
            name="description"
            content="AWS, AWS Amplify, Data Science and much more interesting content! My way of sharing my knowledge with likeminded anyone else who may need it"
          />

          <meta itemprop="name" content="Adam Satvilker - Blog" />
          <meta
            itemprop="description"
            content="AWS, AWS Amplify, Data Science and much more interesting content! My way of sharing my knowledge with likeminded anyone else who may need it"
          />
          <meta
            itemprop="image"
            content="https://www.adamsatvilker.com/logo3.png"
          />

          <meta
            property="og:url"
            content="https://www.adamsatvilker.com/blog"
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Adam Satvilker - Blog" />
          <meta
            property="og:description"
            content="AWS, AWS Amplify, Data Science and much more interesting content! My way of sharing my knowledge with likeminded anyone else who may need it"
          />
          <meta
            property="og:image"
            content="https://www.adamsatvilker.com/logo3.png"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Adam Satvilker - Blog" />
          <meta
            name="twitter:description"
            content="AWS, AWS Amplify, Data Science and much more interesting content! My way of sharing my knowledge with likeminded anyone else who may need it"
          />
          <meta
            name="twitter:image"
            content="https://www.adamsatvilker.com/logo3.png"
          />
        </Helmet>

        <div className="bg-blog img-fluid">
          <MDBMask overlay="black-strong">
            <NavbarPage />
            <div className="d-flex align-items-center justify-content-center">
              <h1 className="white-text ">A GUIDE TO MANY THINGS...</h1>
            </div>
          </MDBMask>
        </div>

        <div id="blogList">
          <MDBRow>
            <MDBCol
              size="12"
              md="2"
              className="d-flex flex-column ml-2 mr-2 ml-md-0 mr-md-0 mt-2 mt-md-5 pt-2 pt-md-5 "
            >
              <ins
                className="adsbygoogle d-none d-md-block blogAd"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6848593650304829"
                data-ad-slot="8733711468"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
              <ins
                className="adsbygoogle d-none d-md-block blogAd"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6848593650304829"
                data-ad-slot="8733711468"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
            </MDBCol>
            <MDBCol size="12" md="8">
              <MDBRow id="navContainer">
                <MDBCol size="12">
                  <BlogNav onClick={this.changeQuery} />
                </MDBCol>
              </MDBRow>
              {this.state.loaded ? (
                <MDBRow id="blogContainer">
                  {this.state.result.length === 0 ? (
                    <p className="message">
                      No posts here yet, or an error occured, please refresh and
                      try again
                    </p>
                  ) : (
                    this.state.result.map((data) => {
                      const link = "/blog/" + data.title.replace(/ /g, "-");

                      return (
                        <MDBCol size="12" md="4">
                          <BlogPreview
                            object={data}
                            key={data.id}
                            link={link}
                          />
                        </MDBCol>
                      );
                    })
                  )}
                </MDBRow>
              ) : (
                <div className="flex-center w-100" style={{ height: "100vh" }}>
                  {" "}
                  <div className="spinner-border text-warning" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </MDBCol>
            <MDBCol
              size="12"
              md="2"
              className="d-flex flex-column  mr-md-0 mt-2 mt-md-5 pt-2 pt-md-5 "
            >
              <ins
                className="adsbygoogle blogAd"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6848593650304829"
                data-ad-slot="8733711468"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
              <ins
                className="adsbygoogle blogAd"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6848593650304829"
                data-ad-slot="8733711468"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
            </MDBCol>
          </MDBRow>
        </div>
        <div id="subscribe">
          <Subscribe />
        </div>
      </div>
    );
  }
}

export default Blog;
