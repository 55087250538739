import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import {
MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBAnimation
} from "mdbreact";
import logo from "../images/SVG/logo3.svg";
import Socials from "./socialsGroup";


class NavbarPage extends Component {
state = {
  isOpen: false
};

toggleCollapse = () => {
  var element = document.getElementById("mynav")
  var showElement = document.getElementById("navbarCollapse")
  this.setState({ isOpen: !this.state.isOpen });
  this.state.isOpen ?  element.classList.contains("top-nav-collapse") ? console.log("") : element.classList.remove('nav-color') : element.classList.add('nav-color')
}

render() {
  return (

      
    
    
        
      <MDBNavbar id="mynav" transparent  dark expand="md" fixed>
        <MDBNavbarBrand href="/">
      <MDBAnimation type="fadeInLeft" duration="1000ms">
      <Link className="nav-link" to="/">
        <img src={logo} alt="" height="50"/>
        <strong style={{color: "white", fontSize:"1.7vh", fontFamily:"'Amiri', serif"}} >Adam Satvilker</strong>
      </Link>
      </MDBAnimation>
     
     
        
        </MDBNavbarBrand>
        
        <MDBNavbarToggler onClick={this.toggleCollapse} />
        <MDBCollapse id="navbarCollapse" isOpen={this.state.isOpen} navbar delay={100} >
        <MDBNavbarNav >
        <MDBNavItem>
        <Socials />
        </MDBNavItem>

      </MDBNavbarNav>
          <MDBNavbarNav  left className="mr-5 pr-5 ">
            
            <MDBNavItem>
              <Link className="nav-link" to="/#about" onClick={this.toggleCollapse}>About</Link> 
            </MDBNavItem>
            <MDBNavItem>
              <li className="nav-item">
              <a href="/blog" className="nav-link Ripple-parent">Blog</a>
              </li>
            </MDBNavItem>
            <MDBNavItem>
            <li className="nav-item">
              <a href="/portfolio" className="nav-link Ripple-parent">Portfolio</a>
              </li>
            </MDBNavItem>
           
          </MDBNavbarNav>
          
        </MDBCollapse>
        
          
          
        
      </MDBNavbar>
      
      
   


    );
  }
}

export default NavbarPage;
