import React from 'react';
import {useForm} from 'react-hook-form';
import { MDBInput, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import { API } from 'aws-amplify';


export default function ContactForm(){

    const {register, handleSubmit, errors, reset} = useForm();

    async function onSubmit(data, e){
        await console.log(data.email);
        
       
        var d = new Date();
        var n = d.toLocaleString();
        var response=data.message.replace(/\n/g, "-")
        const message = await API.graphql({
          query : `mutation create{
              createFeedback(input:{
              name:"${data.name}"
              email: "${data.email}"
              subject:"${data.subject}"
              message:"${response}"
              date:"${n}"
              type:"feedback"
              
            }) {
              id
              
            }}`}); 
        window.location.reload()
        
        
        
        
        
    }
    

    return (
        <MDBRow>
    <MDBCol md="12">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="h5 text-center mb-4">Leave me a message</p>
        <div className="grey-text">
          <MDBInput label="Your name" name="name" icon="user" group type="text" inputRef={register({required:"true"})} id="name123" valueDefault=""/>
          {errors.name && errors.name.type === "required" && <span>Don't forget your name!</span>}
          <MDBInput label="Your email" name="email" icon="envelope" group type="text" inputRef={register({required: "true",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            
          }
        })}
      />
        {errors.email && errors.email.type === "required" && <span>Don't forget your email!</span>}
        {errors.email && errors.email.type === "pattern" && <span>Please enter a valid email address</span>}
          <MDBInput label="Subject" name="subject" icon="tag" group type="text" inputRef={register({required:"true"})}/>
          <MDBInput type="textarea" name="message" rows="2" label="Your message" icon="pencil-alt" inputRef={register({required:"Don't forget your message!"})}/>
          {errors.message&& errors.message.message}
        </div>
        <div className="text-center">
          <MDBBtn type="submit" outline color="white">
            Send
            <MDBIcon far icon="paper-plane" className="ml-1" />
          </MDBBtn>
        </div>
      </form>
    </MDBCol>
    
  </MDBRow>
        
    )
}