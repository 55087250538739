import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import {
MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse} from "mdbreact";
import { API} from "aws-amplify";

const BlogQuery = `query list{
    listCategorys(filter: {topic: {ne: "Portfolio"}}){
      items{
        id
        topic
      }
    }
      
          }`;
const PortfolioQuery = `query list{
  listPortfolioCategorys{
    items{
      id
      topic
    }
  }
    
        }`;
        

class BlogNav extends Component {
    constructor(props)
  {
    
    super(props);
    
    this.state={
        isOpen: false,
        categories: [],
        postType:""
      
    }
    this.toggleCollapse=this.toggleCollapse.bind(this);
  }

toggleCollapse = () => {
  
  this.setState({ isOpen: !this.state.isOpen });
  
}
async componentDidMount()
{   
    const path = window.location.pathname
    const pathArray = path.split("/")
    const postType = pathArray[1]
    this.setState({postType:postType})
    if(postType === "blog")
    {
      const data = await API.graphql({
        query: BlogQuery
  
      });
      
      
      this.setState({
        categories: data.data.listCategorys.items
        
      });
     
    }
    else
    {
      const data = await API.graphql({
        query: PortfolioQuery
  
      });
      
      
      this.setState({
        categories: data.data.listPortfolioCategorys.items
        
      });
    }
    
}
async handleChange(id,link)
{
    console.log(link)
    if(this.state.postType === "blog")
    {
        const data = await API.graphql({
            query: `query list{
                postByDateByCategory(categoryID:"${id}", sortDirection: DESC){
                  items{
                    id
                    image
                    date
                    duration
                    title
                    description
                    link
                    category {
                      topic
                    }
                    
                  }
                }
                  
                      }
                    `
      
          }); 
          
          
      this.props.onClick(data)
    }
  else
  {
    const data = await API.graphql({
      query: `query list{
          portfolioByDateByCategory(categoryID:"${id}", sortDirection: DESC){
            items{
              id
              image
              date
              duration
              title
              description
              link
              category {
                topic
              }
              
            }
          }
            
                }
              `

    }); 
    
    
this.props.onClick(data)
  }
}
handleChangeAllPosts = () =>
{
    
    window.location.reload()
}


render() {
  return (

      
    
    <MDBNavbar id="blogNav" transparent  light expand="md" >
        
        
        
    <MDBNavbarToggler onClick={this.toggleCollapse} style={{position:"absolute", right:"2vh", top:"-3vh"}} />
    <MDBCollapse id="navbarCollapse" isOpen={this.state.isOpen} navbar delay={100}  >
      <MDBNavbarNav left className="mr-5 pr-5">
        <MDBNavItem >
          <MDBNavLink link to="#" className="nav-link" onClick={this.handleChangeAllPosts}>All posts</MDBNavLink>
        </MDBNavItem>
       
       {
           this.state.categories.map(link => {
               
               return (
                <MDBNavItem >
                <MDBNavLink link to="#"className="nav-link"  key={link.id} onClick={() => this.handleChange(link.id,2)}>{link.topic}</MDBNavLink> 
            </MDBNavItem>
               )
           })
       }
        
      </MDBNavbarNav>
      
    </MDBCollapse>
    
      
      
    
  </MDBNavbar>
  
  





        
     
   


    );
  }
}

export default BlogNav
