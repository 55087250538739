import React, { Component } from 'react';
import '../pages/blog.css';
import { MDBMask, MDBContainer, MDBIcon, MDBRow, MDBView } from 'mdbreact';

class BlogPreview extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        image: '',
        web: 'add a blog'
      }
    }
    
       /* componentDidMount(){
        Storage.get(`${this.props.object.image}`).then(response => {
            this.setState({image: response})
        })
      
      
    }   */
  
    render(){
      return(
      
        <div id="blogComponent">
            
            <MDBContainer className="d-flex flex-column justify-content-between align-items-start pt-2 pb-2 mr-5 h-100">
            
            <MDBView hover className="z-depth-3">

             
                   <img src={this.props.object.image} alt="" width="100%" height="auto" />  
                
                <a href={ this.props.link }>
                <MDBMask overlay="black-light" className="flex-center">
                
                </MDBMask>
                </a>
            </MDBView>
            
                    <div className="d-flex flex-row pt-4">
                      <MDBIcon icon="user-circle" size="2x" className="grey-text pr-2"/>
                      <div className="d-flex flex-column ">
                        <h3>Adam Satvilker</h3>
                        <div className="d-flex flex-row">
                     
                        <p className="mr-3 info">{this.props.object.date} &diams; {this.props.object.duration} mins read </p>
                    
                        </div>
                     </div>
                    </div>
            
                  <a href={ this.props.link}><h1>{this.props.object.title}</h1></a>
                  <p>{this.props.object.description}...</p>
             
                  <div className="d-flex  w-100 justify-content-between">
                    
                      
                    <p style={{color:"#6c757d", fontWeight:"bold", fontSize:"0.9rem"}}>#{this.props.object.category.topic.replace(/ /g, "")}</p>
                    
                    <a href={this.props.link} style={{textAlign:"end"}}>Read more></a>

                  </div>
                    
             
            </MDBContainer>
           
        </div>
      
         
               
        
  
      )
    }
  }
  
  export default BlogPreview