import React, {  Component} from 'react';
import '../pages/App.css';


import { MDBMask, MDBContainer } from 'mdbreact';

class Footer extends Component{

    constructor(props)
    {
      super(props);
      this.state={
        
      }
    }
    
   
  
    render(){
      return(
      
        <MDBMask  overlay="black-strong flex-center" style={{height:"auto"}}>
        <div>
            
            <style>
            @import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Cormorant&family=DM+Serif+Display&family=Playfair+Display:wght@500&family=Rye&display=swap');
            </style>
            <style>
            @import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
            </style>
        <MDBContainer className="flex-center white-text flex-column pl-5 pr-5">
            <h2>The end of the road,<br/> for now...</h2>
            <p>Thank you for reading, I hope it was a somewhat pleasant experiece!</p>
        </MDBContainer>       
        <MDBContainer className="d-flex align-items-center white-text flex-column pl-5 pr-5  pt-5">
            <h1>Adam Satvilker</h1>
            
        </MDBContainer>      
           
        </div>
      
        </MDBMask>   
               
        
  
      )
    }
  }
  
  export default Footer